<template>
    <section :class="['competition']" :style="getAPIStyler">
        <div class="medal" v-if="!isLoading && isShowMedal"></div>
        <div v-if="isLoading" class="process-loading-icon" />
        <div
            v-if="!isLoading && !isError && hasApiData"
            :class="['competition_content', { 'content-bg': !isShowMedal }]"
        >
            <div class="competition_content_title">
                <h4 class="title">
                    <div class="title_year">
                        {{ isLoading ? '' : readStoreApi?.year }}
                    </div>
                    <div class="title_content">
                        {{ isLoading ? '' : readStoreApi?.alias }}
                    </div>
                    <div class="title-tablet">
                        {{ isLoading ? '' : '競賽受理進度' }}
                    </div>
                </h4>
                <p class="state">
                    {{ isLoading ? '' : '競賽受理進度' }}
                </p>
                <time class="time"
                    >{{
                        `${isLoading ? '' : readStoreApi?.startDate} ${
                            isLoading ? '' : '至'
                        } ${isLoading ? '' : readStoreApi?.endDate}`
                    }}
                </time>
            </div>
            <div class="competition_content-category">
                <div
                    class="link_item"
                    v-if="!isLoading"
                    @click="
                        $crossPageFilter(
                            'ReportContest',
                            readStoreApi?.payload.personal,
                            {},
                            true
                        ),
                            this.$setGaEvent(
                                `filterCompetitionPersonal`,
                                'cross-page-filter'
                            )
                    "
                >
                    <figure class="rank">
                        <figcaption class="rank_number">
                            {{
                                readStoreApi.useScoreTitle
                                    ? readStoreApi?.personal.score
                                    : ''
                            }}
                        </figcaption>
                    </figure>
                    <div class="performance">
                        <div class="performance-title">個人獎競賽受理業績</div>
                        <div class="performance-total">
                            {{ readStoreApi?.personal.contestFyb }}
                        </div>
                    </div>
                    <!-- 連結按鈕圖檔改使用CSS切版(仍保留圖檔設定) -->
                    <div class="link-tag">
                        <div class="link_item-tag"></div>
                    </div>
                </div>
                <template
                    v-if="
                        this.$store.state?.dashboardCompanyContest.org !==
                            null && !isLoading
                    "
                >
                    <div
                        class="link_item"
                        @click="
                            $crossPageFilter(
                                'ReportContest',
                                readStoreApi?.payload.org,
                                {},
                                true
                            ),
                                this.$setGaEvent(
                                    `filterCompetitionOrg`,
                                    'cross-page-filter'
                                )
                        "
                    >
                        <figure class="rank">
                            <figcaption class="rank_number">
                                {{
                                    readStoreApi.useScoreTitle
                                        ? readStoreApi?.org.score
                                        : ''
                                }}
                            </figcaption>
                        </figure>
                        <div class="performance">
                            <div class="performance-title">
                                主管獎競賽受理業績
                            </div>
                            <div class="performance-total">
                                {{ readStoreApi?.org.contestFyb }}
                            </div>
                        </div>
                        <!-- 連結按鈕圖檔改使用CSS切版(仍保留圖檔設定) -->
                        <div class="link-tag">
                            <div class="link_item-tag"></div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <DashboardErrorMessage
            v-if="!isLoading && isError"
            functionName="getDashboardContestAPI"
            @reacquire="getCompetitionAPI"
        />
        <DashboardNoData
            v-if="!isLoading && !isError && !hasApiData"
            message="沒有近期公司競賽"
        />
        <div class="trophy" v-if="!isLoading && !isSectionShow"></div>
    </section>
</template>
<script>
import DashboardErrorMessage from './DashboardErrorMessage.vue'
import DashboardNoData from './DashboardNoData.vue'
import { getDashboardContestAPI } from '../../assets/javascripts/api'
export default {
    name: 'DashboardCompetition',
    components: { DashboardErrorMessage, DashboardNoData },
    methods: {
        getCompetitionAPI: async function () {
            this.isLoading = true
            this.isError = false
            try {
                let response = await getDashboardContestAPI()
                let contestData = response.data
                contestData.personal.contestFyb = this.$numberWithComma(
                    contestData.personal.contestFyb
                )
                contestData.payload.personal.role =
                    contestData.payload.personal.role ?? 'supervisor'
                if (contestData?.org !== null) {
                    contestData.org.contestFyb = this.$numberWithComma(
                        contestData.org.contestFyb
                    )
                    contestData.payload.org.role =
                        contestData.payload.org.role ?? 'supervisor'
                }
                this.$store.commit('set', {
                    dashboardCompanyContest: contestData
                })
            } catch (error) {
                this.isError = true
                this.$store.commit('set', {
                    dashboardCompanyContest: null
                })
            } finally {
                this.isLoading = false
            }
        }
    },
    data() {
        return {
            competitionAPI: {},
            isLoading: false,
            isError: false,
            isShowMedal: true
        }
    },
    computed: {
        getAPIStyler: function () {
            if (!this.isLoading && !this.hasWholeStyler) {
                //回傳預設樣式
                return {
                    //標題樣式設定
                    '--title-color':
                        'linear-gradient(270deg, #00ADEE 18.35%, #6B8BE4 81.94%)',
                    '--title-border': '#FFFF',
                    //背景樣式設定
                    '--content-background':
                        'linear-gradient(270deg, rgba(0, 173, 238, 0.8) 18.35%, rgba(107, 139, 228, 0.8) 81.94%)',
                    //競賽時間樣式設定
                    '--date-limit': '#FFFF',
                    //業績樣式設定
                    '--performance-color': '#FFFF',
                    //icon樣式設定
                    '--score-icon': `url(${require('@/assets/images/img_star@3x.webp')})`,
                    '--score-text-color':
                        'linear-gradient(270deg, rgba(0, 173, 238, 0.8) 18.35%, rgba(107, 139, 228, 0.8) 81.94%)',
                    //link-button設定
                    '--link-button': `url(${require('@/assets/images/dashboard/btn_circlearrow_right@3x.webp')})`,
                    '--link-color': '',
                    //獎盃、獎牌設定
                    '--medal-icon': `url(${require('@/assets/images/img_medal@3x.webp')})`,
                    '--trophy-icon': `url(${require('@/assets/images/img-trophy@3x.webp')})`
                }
            } else {
                //不同競賽的動態樣式
                return {
                    //標題樣式設定
                    '--title-color': this.readStoreApi?.styler?.titleTextColor,
                    '--title-border': this.readStoreApi?.styler
                        ?.titleBorderColor,
                    //背景樣式設定
                    '--content-background': this.readStoreApi?.styler
                        ?.backgroundImage
                        ? `url(${this.readStoreApi?.styler?.backgroundImage})`
                        : '',
                    //競賽時間樣式設定
                    '--date-limit': this.readStoreApi?.styler
                        ?.dateLimitTextColor,

                    //業績樣式設定
                    '--performance-color': this.readStoreApi?.styler
                        ?.performanceTextColor,
                    //icon樣式設定
                    '--score-icon': this.readStoreApi?.styler?.scoreImage
                        ? `url(${this.readStoreApi?.styler?.scoreImage})`
                        : '',
                    '--score-text-color': this.readStoreApi?.styler
                        ?.scoreTextColor,
                    //link-button設定
                    '--link-button': this.readStoreApi?.styler?.linkButtonImage
                        ? `url(${this.readStoreApi?.styler?.linkButtonImage})`
                        : '',
                    '--link-color':
                        this.readStoreApi?.styler?.linkButtonColor || '',
                    //獎盃、獎牌設定
                    '--medal-icon': this.readStoreApi?.styler?.medalImage
                        ? `url(${this.readStoreApi?.styler?.medalImage})`
                        : '',
                    '--trophy-icon': this.readStoreApi?.styler?.trophyImage
                        ? `url(${this.readStoreApi?.styler?.trophyImage})`
                        : ''
                }
            }
        },
        readStoreApi: function () {
            return this.$store.state.dashboardCompanyContest
        },
        isSectionShow: function () {
            return !this.$store.state.isSectionHidden
        },
        hasApiData: function () {
            if (this.$store.state.dashboardCompanyContest !== null) {
                let hasContestTitle = this.$store.state.dashboardCompanyContest
                    .contestName
                    ? true
                    : false
                return hasContestTitle
            } else {
                return false
            }
        },

        hasWholeStyler: function () {
            let stylerList = this.readStoreApi?.styler
            let hasBackgroundSetting = stylerList?.backgroundImage
                ? true
                : stylerList?.backgroundColor
                ? true
                : false

            let isStylerExist = true
            for (let styleColumn in stylerList) {
                //上面檢查過背景設定，所以跳過背景設定的檢查
                if (
                    styleColumn === 'backgroundImage' ||
                    styleColumn === 'backgroundColor'
                ) {
                    continue
                }
                if (stylerList[styleColumn]) {
                    isStylerExist = true
                } else {
                    isStylerExist = false
                    break
                }
            }
            if (hasBackgroundSetting && isStylerExist) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        readStoreApi: {
            handler(newVal) {
                if (newVal === null) {
                    this.isError = true
                } else {
                    this.isError = false
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (
            this.$store.state.dashboardCompanyContest == null ||
            Object.keys(this.$store.state.dashboardCompanyContest).length ===
                0 ||
            this.$shouldUpdateHomeApi()
        ) {
            this.getCompetitionAPI()
        }
    }
}
</script>
<style lang="scss" scoped>
.process-loading-icon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}
.normal-bg {
    background: $primary-white;
}
.animated-background {
    content: '';
    width: 100%;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $fourth-grey;
    background: linear-gradient(
        to right,
        $third-grey 8%,
        $secondary-white 18%,
        $third-grey 33%
    );
    background-size: 800px 104px;
    box-sizing: border-box;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.text-line {
    @extend .animated-background;
}
.competition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    gap: 10px;

    height: 323px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: $primary-white;

    position: relative;

    @media screen and(min-width:576px) {
        min-width: 517px;
        height: 251px;
    }

    .medal {
        width: 45px;
        height: 63.77px;
        background: var(--medal-icon) no-repeat center/auto 100%;
        position: absolute;
        left: 0%;
        top: 0%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @media screen and(min-width:576px) {
            left: 6%;
        }
        @media screen and(min-width:768px) {
            left: 5%;
        }
        @media screen and(min-width:1078px) {
            left: 5%;
        }
    }
    .trophy {
        display: none;
        @media screen and(min-width:768px) {
            display: block;
            width: 105px;
            height: 110px;
            background: var(--trophy-icon) no-repeat center/auto 100%;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        @media screen and(min-width:1018px) {
            display: none;
        }
        @media screen and(min-width:1280px) {
            display: block;
            background: var(--trophy-icon) no-repeat center/auto 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .content-bg {
        background-position: left;
    }

    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 10px;
        gap: 10px;
        width: 100%;
        min-height: 283px;
        background: var(--content-background) no-repeat center/auto 100%;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-size: cover;
        @media screen and(min-width:576px) {
            background: var(--content-background) no-repeat center/100% 100%;
            min-height: 211px;
            padding: 30px 0;
            gap: 22px;
            background-size: cover;
        }
        @media screen and(min-width:1018px) {
            object-fit: contain;
            min-height: 211px;
            padding: 25px 30px;
            gap: 22px;
            background-size: cover;
        }

        &_title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            width: 100%;
            @media screen and(min-width:576px) {
                gap: 0px;
                width: auto;
            }
            .title {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: baseline;
                gap: 10px;
                min-height: 52px;
                margin: 0;
                background: var(--title-color);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                -webkit-text-stroke: 1px var(--title-border);
                text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.1);
                font-family: Arial, Helvetica, sans-serif; //原為Noto Sans TC,但text-stroke邊框會重疊
                // font-family: 'Noto Sans TC';
                font-weight: 900;

                @media screen and(min-width:576px) {
                    max-height: 69px;
                }
                @media screen and(min-width:1018px) {
                    margin-top: 0px;
                    min-height: 52px;
                    max-height: 0;
                }
                &-tablet {
                    display: none;

                    @media screen and(min-width:576px) {
                        display: block;
                        font-size: 24px;
                    }
                }
                &_content {
                    font-size: 30px;
                    white-space: nowrap;
                    @media screen and(min-width:375px) {
                        font-size: 36px;
                        height: 100%;
                    }
                }
            }

            & :first-child {
                font-size: 24px;
            }
        }
        .state {
            width: 100%;
            height: 24px;

            background: var(--title-color);
            -webkit-background-clip: text;
            -webkit-text-stroke: 0.5px var(--title-border);
            -webkit-text-fill-color: transparent;

            line-height: 24px;
            text-align: center;
            text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.2);
            font-family: Arial, Helvetica, sans-serif; //原為Noto Sans TC,但text-stroke邊框會重疊
            font-weight: 900;
            font-size: 20px;

            margin: 0;

            @media screen and(min-width:576px) {
                display: none;
            }
        }
        .time {
            font-family: 'Noto Sans TC';
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: var(--date-limit);
            @media screen and(min-width:576px) {
                min-width: 379px;
                width: 100%;
                margin: 0 auto;
                margin-top: 0px;
            }
        }
        &-category {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            height: 130px;

            @media screen and(min-width:576px) {
                flex-direction: row;
                gap: 30px;
                height: auto;
            }
            @media screen and(min-width:1018px) {
                gap: 40px;
            }

            & > .link_item {
                margin-bottom: 10px;

                @media screen and(min-width:576px) {
                    margin-bottom: 0;
                }
            }

            & :last-child {
                margin-bottom: 0;
            }
            .link_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 60px;
                width: 215px;
                box-sizing: border-box;

                cursor: pointer;

                .rank {
                    width: 60px;
                    height: 60px;
                    display: flex;
                    flex-shrink: 0;
                    justify-content: center;
                    align-items: center;
                    background: var(--score-icon) no-repeat center/contain;
                    margin: 0;
                    margin-right: 10px;
                    position: relative;
                    &_number {
                        font-family: 'Roboto';
                        font-weight: 900;
                        font-size: 20px;
                        line-height: 24px;

                        min-width: 12px;
                        min-height: 24px;

                        background: var(--score-text-color);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                }
                .performance {
                    width: 125px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 5px;
                    margin-right: 5px;
                    @media screen and(min-width:1018px) {
                        min-width: 120px;
                    }

                    &-title {
                        font-family: 'Noto Sans TC';
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        height: 20px;
                        color: var(--performance-color);
                    }
                    &-total {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 23px;
                        color: var(--performance-color);
                    }
                }
                &-tag {
                    position: absolute;
                    display: block;
                    height: 20px;
                    width: 20px;
                    flex-shrink: 0;
                    top: 0;
                    left: 0;
                    background: var(--link-button) center/contain no-repeat;
                    z-index: 3;
                }
            }
        }
    }
}

.link-tag {
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    background: var(--link-color);
    &::after {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        background-color: $primary-white;
        border-radius: 50%;
        left: 1px;
        top: 1px;
    }
    &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 8px;
        left: 8px;
        top: 6px;
        z-index: 10;
        background: var(--link-color);
        -webkit-mask: url('~@/assets/images/img_dashboard_link_arrow_mask.svg')
            no-repeat center;
        mask: url('~@/assets/images/img_dashboard_link_arrow_mask.svg')
            no-repeat center center;
    }
}
</style>
